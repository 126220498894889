<template>
	<main>
		<header>
			<Logo />
		</header>
		<section>
			<div class="notfound">
				<img :src="require('../assets/notfound.svg')">
				<h1>Error 404</h1>
				<h2>Página não encontrada.</h2>
				<a href="/">Voltar ao Inicio</a>
			</div>
		</section>
	</main>
</template>

<script>
import Logo from '@components/Logo';

export default {
	name: 'NotFound',
	components: {
		Logo
	}
}
</script>

<style lang="scss" scoped>
header {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 116px;
	background: var(--background-not);
	border-radius: 0px 0px 20px 20px;

	::v-deep .logo {
		margin: auto;
		width: 275px;
	}
}

section {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 200px);
}

.notfound {
	padding: 24px;
	text-align: center;

	h1 {
		font-size: 44px;
	}

	h2 {
		font-size: 28px;
		margin-bottom: 10px;
	}

	a {
		font-size: 18px;
	}
}

</style>